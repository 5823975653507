const formatFeaturedEvent = event => {
  let { image, path, excerpt, ...rest } = event;

  return {
    image,
    event: {
      ...rest,
      link: path,
      body: excerpt,
    },
  };
};

const formatEventsList = events => {
  return events.map(event => {
    let { path, excerpt, ...rest } = event;
    return {
      link: path,
      body: excerpt,
      ...rest,
    };
  });
};

export { formatFeaturedEvent, formatEventsList };
