import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../layouts";
import SEO from "../components/seo";
import Header from "../modules/header";
import FeaturedPost from "../modules/featured-post";
import FeaturedList from "../modules/featured-list";
import { formatFeaturedEvent, formatEventsList } from "../helpers/event";
import PageBuilder from "../modules/page-builder";

export const query = graphql`
  query EventsPageQuery($id: String!) {
    page: sanityPageEvents(id: { eq: $id }) {
      title
      slug {
        current
      }
      seo {
        title
        keywords
        image {
          asset {
            url
          }
        }
        description
      }
      ...eventsIndexBuilderFields
      breadcrumbs {
        title
        slug
      }
      featureExternalEvent
      externalEvent {
        title
        location
        link
        image {
          asset {
            _id
            altText
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
          hotspot {
            height
            width
            x
            y
          }
        }
        eventType
        dateStart
        dateEnd
        body
      }
      image {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      heroTitle
      heroText
      allEventsTitle
      hideUpcomingEvents
      featured {
        _id
        title
        excerpt
        dateEnd
        dateStart
        eventType
        path
        location
        image {
          asset {
            _id
            altText
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
          hotspot {
            height
            width
            x
            y
          }
        }
      }
    }
    events: allSanityEvent(
      filter: { isFuture: { eq: true } }
      sort: { fields: dateStart, order: ASC }
    ) {
      nodes {
        _id
        title
        excerpt
        dateEnd
        dateStart
        eventType
        path
        location
        image {
          asset {
            _id
            altText
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
          hotspot {
            height
            width
            x
            y
          }
        }
      }
    }
  }
`;

const EventsIndex = ({ data }) => {
  const page = data.page;
  let events = data.events.nodes;

  let featured;
  if (page.featureExternalEvent) {
    featured = {
      image: page.externalEvent?.image,
      excerpt: page.externalEvent?.body,
      path: page.externalEvent?.link,
      ...page.externalEvent,
    };
  } else {
    featured = page.featured;
  }
  let featuredEvent;
  if (featured) {
    // Remove featured event from Events List
    events = events.filter(event => event._id !== featured._id);
  } else {
    // Grab first event from Events List and feature it
    featured = events.shift();
  }

  if (featured) {
    // Restructure featured event data
    featuredEvent = formatFeaturedEvent(featured);
  }

  return (
    <Layout className="events-index">
      <SEO
        title={page.title}
        excerpt={page.excerpt}
        image={page.image}
        seo={page.seo}
      />
      <Header
        title={page.heroTitle}
        text={page.heroText}
        breadcrumbs={page.breadcrumbs}
        image={page.image}
        backgroundColour={"blue"}
        textColour="white"
      />
      <section className="p-events-body">
        {(events?.[0] || featuredEvent) && (
          <>
            <FeaturedPost
              event={featuredEvent.event}
              image={featuredEvent.image}
            />
            {events?.[0] && !page.hideUpcomingEvents && (
              <FeaturedList
                events={formatEventsList(events)}
                title={page.allEventsTitle}
              />
            )}
          </>
        )}
      </section>
      <PageBuilder builder={page.pageBuilder} />
    </Layout>
  );
};

EventsIndex.propTypes = {
  data: PropTypes.object,
};

export default EventsIndex;
