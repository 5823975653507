import React from "react";
import PropTypes from "prop-types";
import BasicImage, { BasicImagePropTypes } from "../elements/basic-image";
import EventCard, { EventCardPropTypes } from "../components/card/event-card";
import NewsCard, { NewsCardPropTypes } from "../components/card/news-card";

const FeaturedPost = ({ event, news, image, alignment = "left" }) =>
  (news || event) &&
  !(news && event) && (
    <div className="m-featured-post">
      <div className={`m-featured-post-inner inner -${alignment}`}>
        <div className="m-featured-post-list-item">
          <div
            className={`m-featured-post-list-item-inner${
              image ? "" : " -noimage"
            }`}
          >
            {event && <EventCard {...event} largeHeading={true} />}
            {news && <NewsCard {...news} largeHeading={true} />}
          </div>
        </div>
        {image && (
          <div className="m-featured-post-image-container">
            <div className="m-featured-post-image">
              <BasicImage image={image} />
            </div>
          </div>
        )}
      </div>
    </div>
  );

FeaturedPost.propTypes = {
  event: PropTypes.shape(EventCardPropTypes),
  news: PropTypes.shape(NewsCardPropTypes),
  image: PropTypes.shape(BasicImagePropTypes),
  alignment: PropTypes.oneOf(["left", "right"]),
};

export default FeaturedPost;
